/*!jslint
  this
*/
jQuery(function($) {
  "use strict";
    //Sticky header
    $(window).on('scroll', function (e) {
      if ($(this).scrollTop() >= 100) {
        // $(".site-header").addClass("sactive");
        $(".site-header").delay(60).queue(function(next) {
          $(".site-header").addClass('sactive');
          next();
        });
      } else {
        // $(".site-header").removeClass("sactive");
        $(".site-header").delay(10).queue(function() {
          $(".site-header").removeClass('sactive').dequeue();
        });

      }
    });

    //Service Small
    //How we operate
    if($('.ss-block-content').length && $(window).width() > 769 ) {
      var one =  $( ".1b" );
      var two = $( ".2b" );
      var three = $( ".3b" );
      var four = $( ".4b" );
      var five = $( ".5b" );

        one.addClass('full one');
        two.addClass('two');
        three.addClass('three');
        four.addClass('four');
        five.addClass('five');

        one.on('click', function (e) {
          if(one.hasClass('two')) {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
            one.addClass('full three');
            two.addClass('one');
            three.addClass('two');
            four.addClass('four');
            five.addClass('five');
          } else {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
            one.addClass('full one');
            two.addClass('two');
            three.addClass('three');
            four.addClass('four');
            five.addClass('five');
          }
          
        });
        
        two.on('click', function (e) {
          if(two.hasClass('one')) {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
            one.addClass('two');
            two.addClass('full one');
            three.addClass('three');
            four.addClass('four');
            five.addClass('five');
          } else {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
            one.addClass('one');
            two.addClass('full three');
            three.addClass('two');
            four.addClass('four');
            five.addClass('five');
          }
          
        });
        
        three.on('click', function (e) {
          if(three.hasClass('three')) {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
            one.addClass('one');
            two.addClass('two');
            three.addClass('full three');
            four.addClass('four');
            five.addClass('five');
          } else if(three.hasClass('four')) {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
              one.addClass('one');
              two.addClass('two');
              three.addClass('full five');
              four.addClass('three');
              five.addClass('four');
          } else {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
            one.addClass('two');
            two.addClass('three');
            three.addClass('full one');
            four.addClass('four');
            five.addClass('five');
          }
          
        });
        four.on('click', function (e) {
          $( ".ss-block-content-box" ).removeClass('full one two three four five');
          one.addClass('one');
          two.addClass('two');
          three.addClass('three');
          four.addClass('full five');
          five.addClass('four');
        });
        five.on('click', function (e) {
          if(five.hasClass('four')) {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
            one.addClass('one');
            two.addClass('two');
            three.addClass('four');
            four.addClass('five');
            five.addClass('three full');
          } else {
            $( ".ss-block-content-box" ).removeClass('full one two three four five');
            one.addClass('one');
            two.addClass('two');
            three.addClass('three');
            four.addClass('four');
            five.addClass('five full');
          }
          
        });
        

    } else {
      $( ".ss-block-content-box" ).on('click', function (e) {

       $( ".ss-block-content-box" ).removeClass('full');
        $(this).addClass('full')
      })
    }
    //AOS intiate
     // AOS.init();

      if($('.ph-header')) {
        setTimeout(function() {
          $('.ph-header').addClass('active');
        }, 100);
        setTimeout(function() {
          $('.ph-header').addClass('activeup');
        }, 500);
      }

    //Header animation
    if($('.ph').length) {
      var mainSection = document.querySelector('.ph');

    function collapseSection(element) {
      // get the height of the element's inner content, regardless of its actual size
      var sectionHeight = element.scrollHeight;
      
      // temporarily disable all css transitions
      var elementTransition = element.style.transition;
      element.style.transition = '';
      
      // on the next frame (as soon as the previous style change has taken effect),
      // explicitly set the element's height to its current pixel height, so we 
      // aren't transitioning out of 'auto'
      requestAnimationFrame(function() {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;
        if ($(window).width() > 768) {
          mainSection.style.height = sectionHeight+200 + 'px';
          mainSection.style.transition = elementTransition;
        } else {
          mainSection.style.height = sectionHeight+80 + 'px';
          mainSection.style.transition = elementTransition;
        }

        requestAnimationFrame(function() {
          element.style.height = sectionHeight + 'px';
        });
      });
      element.setAttribute('data-collapsed', 'true');
    }

    function expandSection(element) {
      var sectionHeight = element.scrollHeight;
      element.style.height = sectionHeight + 'px';
      element.addEventListener('transitionend', function(e) {
        element.removeEventListener('transitionend', arguments.callee);
        element.style.height = null;
      });
      element.setAttribute('data-collapsed', 'false');
    }

    setTimeout(function() {
      var section = document.querySelector('.ph .collapsible');
     
      var isCollapsed = section.getAttribute('data-collapsed') === 'true';
        
      if(isCollapsed) {
        expandSection(mainSection)
        mainSection.setAttribute('data-collapsed', 'false')
      } else {
        collapseSection(section)
      }
    }, 1000);
    }
    //Mobile Menu
    $(document).on('click', '.nav-toggle', function (e) {
      $('body').toggleClass('nav-open');
    });

    //Swiper
    if($('.comm-slider-swipe').length) {
      const swiper = new Swiper('.comm-slider-swipe', {
        // Optional parameters
       // direction: 'horizontal',
        loop: true,
        centeredSlides:false,
        centeredSlidesBounds:false,
        slidesPerView: "1",
        autoHeight:false,
        loopAddBlankSlides:true,
        
        autoplay: {
          delay: 3000,
        },
        //spaceBetween: 30,
        mousewheel: true,
          // Responsive breakpoints
      breakpoints: {
        768: {
          direction: 'vertical',
          slidesPerView: 3,
          autoHeight:true,
          centeredSlides:true,
          centeredSlidesBounds:true,
          freeMode: {
            enabled: true,
            sticky: true,
          },
        },
        992: {
          direction: 'vertical',
          slidesPerView: 5,
          autoHeight:true,
          centeredSlides:true,
          centeredSlidesBounds:true,
          freeMode: {
            enabled: true,
            sticky: true,
          },
        }
      }
      });

    }

    //Accordion

    if($('.sea').length) {

    if (location.hash !== null && location.hash !== "") { 

      if($(location.hash).hasClass( "show" )) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
      }
     
        setTimeout(function() {
          
          if(!$(location.hash).hasClass( "show" )) {
            $(location.hash + ".collapse").collapse("show"); 
            setTimeout(function() {
              $("html, body").animate({ 
                scrollTop: $(location.hash + ".collapse.show").offset().top  - 350
              }, 1000);
            }, 1200);
          } else {
            
            setTimeout(function() {
              $("html, body").animate({ 
                scrollTop: $(location.hash + ".collapse.show").offset().top  - 700
              }, 1000);
            }, 0);
          }
        
          
        }, 1100);

      } 
    }

    //How we operate
    if($('.ope-box').length) {
      const opeBox = new Swiper('.ope-box', {
        // Optional parameters
        direction: 'vertical',
        loop: false,
        slidesPerView: 1,
        slidesPerGroup:1,
        //autoHeight:true,
        draggable:true,
        freeMode: {
          enabled: true,
          sticky: true,
        },
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true
        // },
        //cssMode:true,
        mousewheelControl: true,
        // scrollbar: {
        //   enabled:true,
        //   hide:true,
        // },
        // autoplay: {
        //   delay: 5000,
        // },
        mousewheel: {
          releaseOnEdges: true,
          invert: false,
        },
        breakpoints: {
          992: {
            slidesPerView: 2,
            slidesPerGroup:2,
            mousewheel: {
              releaseOnEdges: true,
              invert: false,
              //forceToAxis: true
            },
            // scrollbar: {
            //   el: '.swiper-scrollbar',
            //   draggable:true
            // },
          }
        }
      });
    }

    //Jobs listing
    if($('.jobs-slider').length){
      const swiper = new Swiper('.jobs-slider', {
        // Optional parameters
       //loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        // Navigation arrows
        //navigation: false,
        watchOverflow: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          enabled: false,
        },
        breakpoints: {
          768: {
            slidesPerView: 2.3,
            spaceBetween: 50,
            navigation: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              enabled: true,
            },
            pagination: {
              enabled: false,
            },
          }, 
          992: {
            slidesPerView: 2.5,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              enabled: true,
            },
            pagination: {
              enabled: false,
            },
          }
        }
      });
    }

    //Cookies
    window.addEventListener('load', function() {

      if (localStorage) {
        var cooki = localStorage.getItem( 'cooki' );
  
        if (cooki > new Date()) {
          return
        }
        var expires = new Date();
        expires = expires.setHours(expires.getHours() + 720);
        localStorage.setItem( 'cooki', expires );
        $("#cook").addClass('active');
      }
  
      $(document).on('click', '.co-grid-button-btn, .co-close', function (e) {
          $("#cook").addClass('close');
          setTimeout(function(){
            $("#cook").addClass('closed');
          }, 500);
      });
  
    });

    //Apply Now popup
    if($('.pbody-btn').length){
      $('.pbody-btn').magnificPopup({
        type: 'iframe',
        mainClass: 'apply-form',
      });
    }

    //Form Field duplicate
    //Contact Form (last name and company)
    $(document).on('change', 'input[name="text-11"]', function() {
      $('input[name="text-372"]').val($(this).val());
    });
    $(document).on('change', 'input[name="text-11"]', function() {
      $('input[name="text-72"]').val($(this).val());
    });

    //Let's talk (last name and company)
    $(document).on('change', 'input[name="text-111"]', function() {
      $('input[name="text-794"]').val($(this).val());
    });
    $(document).on('change', 'input[name="text-111"]', function() {
      $('input[name="text-770"]').val($(this).val());
    });

   //Start a Project (last name)
    $(document).on('change', 'input[name="text-438"]', function() {
      $('input[name="text-447"]').val($(this).val());
    });

});
